* { font-family: 'Syne Mono', sans-serif; }

/* DREAM */
.sandman-page {
  background-image: url("./images/bg-sandman-character.png"), url("./images/bg-sandman.jpeg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.sandman-page .link-danger {
  color: #D50000 !important;
}

.sandman-page .rugdoc small {
  top: -6px;
}

.sandman-page .neon {
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}

.sandman-page .low-risk {
  color: #00ABE0;
}

.sandman-page .kyc {
  color: #FFB52C;
}

.sandman-page .defiyield {
  background-color: rgba(255, 255, 255, .25);
}

/* DELIRIUM */
.delirium-page {
  background-image: url("./images/bg-delirium-character.png"), url("./images/bg-delirium.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto 90%, cover;
}

.delirium-page h1 {
  color: #C5D483;
  font-family: 'Bangers', sans-serif;
  font-size: 7rem;
  font-weight: 600;
}

.delirium-page h1 span {
  color: #84D4CE;
  font-family: 'Bangers', sans-serif;
}

.delirium-page h2 {
  color: #C5D483;
  font-family: 'Bangers', sans-serif;
  font-size: 5.5rem;
  font-weight: 600;
}

.delirium-page h2 span {
  color: #84D4CE;
  font-family: 'Bangers', sans-serif;
}


.delirium-page .pending {
  border: 1px solid #84D4CE;
  background-color: rgba(0, 0, 0, .5);
  color: #84D4CE;
}

.btn-link.disabled, .btn-link:disabled {
  color: #e6e6e6;
}

.shadow-card {
  box-shadow: 0 6px 10px 0 rgba(238, 0, 0, 0.14), 0 1px 18px 0 rgba(238, 0, 0, 0.12), 0 3px 5px -1px rgba(238, 0, 0, 0.20) !important;
}

.card-link {
  text-decoration: none;
  background-color: rgba(38, 38, 38, .5);
}

.card-link:hover {
  background-color: rgba(224, 224, 224, .16);
}

/* DESPAIR */
.despair-page {
  background-image: url("./images/bg-despair-character.png"), url("./images/bg-mirror.png"), url("./images/bg-despair.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto 80%, auto 80%, cover;
}

.despair-page h1 {
  color: #AAA;
  font-family: 'Bangers', sans-serif;
  font-size: 7rem;
  font-weight: 600;
}

.despair-page h2 {
  color: #AAA;
  font-family: 'Bangers', sans-serif;
  font-size: 5.5rem;
  font-weight: 600;
}

.despair-page .card-link {
  background-color: rgba(38, 38, 38, .66);
}

.despair-page .card-link:hover {
  background-color: rgba(38, 38, 38, .5);
}

.despair-page .pending {
  border: 1px solid #999;
  background-color: rgba(0, 0, 0, .5);
  color: #999;
}

.despair-page footer {
  background-color: rgba(38, 38, 38, .33);
  padding-top: 6px;
  padding-bottom: 6px;
}

/* DESTRUCTION */
.destruction-page {
  background-image: url("./images/bg-destruction-character.png"), url("./images/bg-destruction.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto 80%, cover;
}

.destruction-page h1 {
  color: #2b487d;
  font-family: 'Bangers', sans-serif;
  font-size: 4rem;
  font-weight: 600;
}

.destruction-page h2 {
  color: #2b487d;
  font-family: 'Bangers', sans-serif;
  font-size: 5.5rem;
  font-weight: 600;
}

.destruction-page .card-link {
  background-color: rgba(38, 38, 38, .66);
}

.destruction-page .card-link:hover {
  background-color: rgba(38, 38, 38, .5);
}

.destruction-page .pending {
  border: 1px solid #999;
  background-color: rgba(0, 0, 0, .5);
  color: #999;
}

.destruction-page footer {
  background-color: rgba(38, 38, 38, .33);
  padding-top: 6px;
  padding-bottom: 6px;
}

.desire-page {
  background-image: url("./images/bg-desire.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.desire-page h1 {
  color: #F3C802;
  font-family: 'Bangers', sans-serif;
  font-size: 7rem;
  font-weight: 600;
}

.destiny-page {
  background-image: url("./images/bg-destiny.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.destiny-page h1 {
  color: #FAF374;
  font-family: 'Bangers', sans-serif;
  font-size: 7rem;
  font-weight: 600;
}

.death-page {
  background-image: url("./images/bg-death.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.death-page h1 {
  color: #FAF374;
  font-family: 'Bangers', sans-serif;
  font-size: 7rem;
  font-weight: 600;
}

@media (min-width: 992px) {
  .mx-lg-9 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  /* DESPAIR */
  .despair-page {
    background-image: url("./images/bg-despair-character.png"), url("./images/bg-mirror.png"), url("./images/bg-despair.jpg");
    background-size: 80% auto, 80% auto, cover;
  }

  /* DESTRUCTION */
  .destruction-page {
    background-image: url("./images/bg-destruction-character.png"), url("./images/bg-destruction.jpg");
    background-size: 80% auto, cover;
  }
}
